import "dayjs/locale/en"
import "dayjs/locale/de"
import "dayjs/locale/fr"
import "dayjs/locale/it"
import { DateInput } from "@mantine/dates"
import classNames from "classnames"
import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect } from "react"
import * as React from "react"
import { useController, UseControllerProps } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Calendar as CalendarIcon } from "assets/icons"
import { TDateFormat } from "types"

type TProps = {
  controllerProps: UseControllerProps
  label?: string | null
  onChange?: (date: TDateFormat | null) => void
  onBlur?: (date: TDateFormat | null) => void
  allowDatesBeforeToday?: true
  allowDatesAfterToday?: true
  disabled?: boolean
  id?: string
  initialDateValue?: string
  clearable?: boolean
  minDate?: Date
  maxDate?: Date
}

dayjs.extend(customParseFormat);

export default function CustomDatePicker(props: TProps): JSX.Element {
  const {
    controllerProps,
    onChange,
    onBlur,
    allowDatesBeforeToday,
    allowDatesAfterToday,
    disabled,
    id,
    initialDateValue,
    clearable = false,
    minDate,
    maxDate,
    ...datepickerProps
  } = props

  const { t, i18n } = useTranslation("datepickerComponent")

  const { field, fieldState } = useController(controllerProps)

  const minimumDate = React.useMemo(() => {
    if (!!minDate) {
      return minDate
    }

    return allowDatesAfterToday
      ? dayjs(new Date()).add(1, "day").toDate()
      : undefined
  }, [minDate, allowDatesAfterToday])

  const maximumDate = React.useMemo(() => {
    if (!!maxDate) {
      return maxDate
    }

    return allowDatesBeforeToday
      ? dayjs(new Date()).subtract(1, "day").toDate()
      : undefined
  }, [maxDate, allowDatesBeforeToday])

  useEffect(
    function clearRequiredErrorOnDatePick() {
      if (field.value && fieldState.error?.type === "required") {
        field.onBlur()
      }
    },
    [field, fieldState]
  )

  function handleOnBlur() {
    field.onBlur()
    if (onBlur) {
      onBlur(
        field.value
          ? (dayjs(field.value as unknown as Date).format(
              "YYYY-MM-DD"
            ) as TDateFormat)
          : null
      )
    }
  }

  return (
    <DateInput
      id={id}
      locale={i18n.language}
      placeholder={`${t("chooseDate")}`}
      minDate={minimumDate}
      maxDate={maximumDate}
      {...datepickerProps}
      value={
        field.value
          ? dayjs(field.value as TDateFormat).toDate()
          : initialDateValue
          ? dayjs(initialDateValue).toDate()
          : null
      }
      onChange={(date) => {
        field.onChange(date ? dayjs(String(date)).format("YYYY-MM-DD") : null)
        if (onChange) {
          onChange(
            date
              ? (dayjs(String(date)).format("YYYY-MM-DD") as TDateFormat)
              : null
          )
        }
      }}
      onBlur={handleOnBlur}
      valueFormat="DD.MM.YYYY"
      icon={<CalendarIcon className="icon mt-[1px] text-black" />}
      clearable={!disabled && clearable}
      disabled={disabled}
      dateParser={(dateString: string) => {
        const inputFormatRegex = /\d{1,2}\.\d{1,2}\.\d{4}/gm
        if (!dateString.match(inputFormatRegex)) return new Date(Date.now())

        const parsed = dayjs(dateString, ["D.M.YYYY", "DD.MM.YYYY"], true);

        return parsed.toDate()
      }}
      classNames={{
        label: "labelTop font-[700] font-default",
        input: classNames([
          "h-10 py-2",
          "text-base",
          "border-black rounded-md",
          "focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500",
          disabled && "!bg-gray-200 !text-black !opacity-50",
        ]),
        day: "hover:bg-blue-500 hover:!text-white",
      }}
    />
  )
}
